import { useEffect, useRef } from "react";

export default function MainPage() {
  let raiting  = useRef(null);
  useEffect(() => {
    // raiting = raiting.current;
    
  }, []);
  const handleRaiting = (e) => {
    // console.log(raiting);    
    // console.log(e);
    for(let index = 0; index < raiting.current.children.length; index++) {
      raiting.current.children[index].classList.remove("bg-rose-400");
      raiting.current.children[index].classList.add("bg-gray-300");
      raiting.current.children[index].children[0].classList.add('hidden')
    }
    for(let index = 0; index <= e.target.value; index++) {
      raiting.current.children[index].classList.add("bg-rose-400");
      raiting.current.children[index].classList.remove("bg-gray-300");
      raiting.current.children[index].children[0].classList.remove('hidden')
    }
  }
  let i=0;
  return (
    
    <div className="flex flex-col justify-center w-screen h-screen gap-5">
      <h1 className="text-center font-bold text-rose-300 text-xl">
        Am I Look Beautiful Today?
      </h1>
      <div className="flex flex-row justify-center gap-3 relative" ref={raiting}>
        <button className="w-10 h-2 rounded-md transition-all duration-200 cursor-pointer flex flex-row justify-center bg-rose-400" onClick={handleRaiting} value={i++}>
          <p className="text-2xl mt-4 select-none pointer-events-none">🤨</p>
        </button>
        <button className="w-10 h-2 rounded-md bg-gray-300 transition-all duration-200 cursor-pointer flex flex-row justify-center " onClick={handleRaiting} value={i++}>
          <p className="text-2xl mt-4 hidden select-none pointer-events-none">
            🙂
          </p>
        </button>
        <button className="w-10 h-2 rounded-md bg-gray-300 transition-all duration-200 cursor-pointer flex flex-row justify-center " onClick={handleRaiting} value={i++}>
          <p className="text-2xl mt-4 hidden select-none pointer-events-none">
            😊
          </p>
        </button>
        <button className="w-10 h-2 rounded-md bg-gray-300 transition-all duration-200 cursor-pointer flex flex-row justify-center " onClick={handleRaiting} value={i++}>
          <p className="text-2xl mt-4 hidden select-none pointer-events-none">
            😚
          </p>
        </button>
        <button className="w-10 h-2 rounded-md bg-gray-300 transition-all duration-200 cursor-pointer flex flex-row justify-center " onClick={handleRaiting} value={i++}>
          <p className="text-2xl mt-4 hidden select-none pointer-events-none">
            😍
          </p>
        </button>
      </div>
    </div>
  );
}
